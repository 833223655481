import styled from 'styled-components';
import images from '../../assets/images';
import IconMew from '../../components/icons/IconMew';
import StyledTitle from '../../styles/StyledTitle';
import { Icon } from '@iconify/react';
const HomeAmm = () => {
    return (
        <StyledAmm className="relative">
            <div className="over"></div>
            <div className="over over-2"></div>
            <div className="container">
                <div className="inner flex items-center flex-col-reverse lg:flex-row gap-[36px]">
                    <div className="box-text w-fit">
                        <div className="box-title relative mb-[28px] lg:mb-[42px]">
                            <div className="icon">
                                <IconMew />
                            </div>
                            <StyledTitle className="">AMM Mew-O</StyledTitle>
                        </div>
                        <div className="text-desc flex items-center gap-6 mb-10">
                            <div className="text-28 lg:text-[50px] text-pri min-w-[20px] lg:min-w-[60px]">
                                <Icon icon="material-symbols:line-end-arrow-notch-rounded" />
                            </div>
                            <p className="font-medium text-lg lg:text-20">Make NFTs always liquidity.</p>
                        </div>
                        <div className="text-desc flex items-center gap-6 mb-10">
                            <div className="text-28 lg:text-[50px] text-pri min-w-[20px] lg:min-w-[60px]">
                                <Icon icon="material-symbols:line-end-arrow-notch-rounded" />
                            </div>
                            <p className="font-medium text-lg lg:text-20">
                                NFT has more use cases- Liquidity Providers.{' '}
                            </p>
                        </div>
                        <div className="text-desc flex items-center gap-6 mb-10">
                            <div className="text-28 lg:text-[50px] text-pri min-w-[28px] lg:min-w-[60px]">
                                <Icon icon="material-symbols:line-end-arrow-notch-rounded" />
                            </div>
                            <p className="font-medium text-lg lg:text-20">
                                Liquid providers can earn by farming Better user experience Decentralized trading with
                                low transaction fees.
                            </p>
                        </div>
                    </div>
                    <div className="box-img xl:w-[1000px] relative">
                        <div className="over"></div>
                        <img src={images.amm} alt="Amm" className="w-full" />
                    </div>
                </div>
            </div>
            {/* <div className="footer w-full absolute bottom-0 hidden xl:block">
                <img src={images.footer} alt="footer" className="object-cover w-full" />
            </div> */}
        </StyledAmm>
    );
};

const StyledAmm = styled.div`
    margin-top: 100px;
    .over {
        background: #2d564b;
        filter: blur(225px);
        width: 726px;
        height: 726px;
        position: absolute;
        top: -50%;
        left: -10%;
        z-index: -1;

        @media screen and (max-width: 48em) {
            top: 10%;
            width: 240px;
            height: 240px;
            filter: blur(50px);
            left: -30%;
        }
    }
    .over-2 {
        left: 60%;
        @media screen and (max-width: 48em) {
            top: 50%;
        }
    }
    .icon {
        position: absolute;
        top: 50%;
        left: 30%;
        transform: translate(-50%, -50%);
        svg {
            @media screen and (max-width: 48em) {
                width: 44px;
            }
        }
    }
    .box-img {
        .over {
            background: #2d564b;
            filter: blur(225px);
            width: 200px;
            height: 200px;
            position: absolute;
            top: 70%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: -1;
        }
    }
    @media screen and (max-width: 64em) {
        margin-top: 40px;
    }
`;

export default HomeAmm;
