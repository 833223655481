import styled from 'styled-components';
import { Child } from '../types/global';

const StyledTitle = ({ children, className }: Child) => {
    return <Styled className={` ${className}`}>{children}</Styled>;
};
const Styled = styled.h2`
    font-weight: 700;
    font-size: 81.3793px;
    line-height: 122px;
    text-shadow: 1px 0px 0px #1b2828, 3px 0px 0px #c4a946;
    @media screen and (max-width: 48em) {
        font-size: 30.3252px;
        line-height: 45px;
    }
`;

export default StyledTitle;
