import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import DefaultLayout from './components/layouts/DefaultLayout';
import BackStory from './pages/backstory/BackStory';
import Docs from './pages/docs/Docs';
import Home from './pages/home/Home';

function App() {
    const routes = [
        { path: '/', component: Home },
        { path: '/backstory', component: BackStory },
        { path: '/docs', component: Docs },
    ];

    return (
        <div className="App">
            <Router>
                <Routes>
                    {routes.map((route, index) => {
                        const Page = route.component;
                        return (
                            <Route
                                key={index}
                                path={route.path}
                                element={
                                    <DefaultLayout>
                                        <Page />
                                    </DefaultLayout>
                                }
                            />
                        );
                    })}
                </Routes>
            </Router>
        </div>
    );
}

export default App;
