import styled from 'styled-components';
import images from '../../assets/images';
import HomeAmm from './HomeAmm';
import HomeBanner from './HomeBanner';
import HomeFreeze from './HomeFreeze';

const Home = () => {
    return (
        <StyledHome className="home overflow-hidden relative">
            <HomeBanner />
            {/* <HomeBackstory /> */}
            <HomeFreeze />
            <HomeAmm />
            <div className="footer w-full ">
                <img src={images.footer} alt="footer" className="object-cover w-full" />
            </div>
            <div className="noise absolute inset-0 z-[-1]">
                <img src={images.noise} alt="noise" />
            </div>
        </StyledHome>
    );
};

const StyledHome = styled.div`
    .noise {
        opacity: 0.45;
    }
`;

export default Home;
