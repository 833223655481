import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../../components/button/Button';

type Props = {
    className?: string;
};
const HomeBackstory = ({ className }: Props) => {
    return (
        <StyledHomeBack
            className={`xl:mt-[0px] 2xl:mt-[0] flex items-center justify-between gap-7 lg:gap-12 ${className}`}
        >
            <div className="box box-1"></div>
            <Link to="/backstory">
                <Button primary hover className="w-[120px] h-[39px] lg:w-[236px] lg:h-[60px] text-lg lg:text-[30px]">
                    Back Story
                </Button>
            </Link>
            <div className="box box-2"></div>
        </StyledHomeBack>
    );
};

const StyledHomeBack = styled.div`
    position: absolute;
    bottom: -10%;
    width: 100%;
    z-index: 50;
    .box {
        border-top: 1px solid #ffe174;
        border-bottom: 1px solid #ffe174;
        height: 29px;
        width: 50%;
        @media screen and (min-width: 1025px) {
            height: 45px;
        }
    }
`;

export default HomeBackstory;
