import React from 'react';
import styled from 'styled-components';
import images from '../../assets/images';
import Button from '../../components/button/Button';
import sc from '../../views';

const BackStoryContent = () => {
    return (
        <StyledSection>
            <div className="container">
                <div className="inner ">
                    <div className="box relative lg:flex xl:justify-between 2xl:justify-end items-center  2xl:mt-[80px]">
                        <div className="box-img lg:w-[560px]  2xl:-translate-x-[60%] ">
                            <img
                                src={images.spaceship}
                                alt="spaceship"
                                className="mx-auto max-w-[236px] md:max-w-[none] my-10 lg:my-0"
                            />
                        </div>
                        <div>
                            <p className="note lg:max-w-[547px]">
                                After months of tracing down the signals that the cat had left, it turned out that there
                                were approximately 5000 cats as replicants of Mew-O.
                            </p>
                            <p className="note lg:max-w-[547px] xl:mt-5">
                                Though different, they all have a ring of light underfoot in common representing their
                                power and energy.
                            </p>
                        </div>
                    </div>
                    <div className="box mt-20 lg:flex items-center justify-between ">
                        <div className="box-img flex relative lg:block">
                            <p className="note max-w-[230px] lg:max-w-[676px]">
                                Research supports the hypothesis that due to the DNA that scientists had implanted into
                                the cat before sending it into space, Mew-O not only survived but also evolved a gene
                                mutation, allowing it to create replications.
                            </p>
                            <img
                                src={images.hanhtinh}
                                alt="spaceship"
                                className="mx-auto absolute -right-10 lg:hidden max-w-[180px] lg:max-w-[none]"
                            />
                            <p className="note mt-10 hidden lg:block">
                                More importantly, the average lifespan of this mutant cat obviously increased and even
                                went above and beyond. The ring of light under their feet also enabled them to send
                                signals to one another from far away and supply the major energy so that they could
                                become physically fast-moving and agile.
                            </p>
                        </div>
                        <div className="box-img hidden lg:block">
                            <img
                                src={images.hanhtinh}
                                alt="spaceship"
                                className="mx-auto absolute -right-10 lg:static"
                            />
                        </div>
                        <p className="note mt-5 lg:hidden">
                            More importantly, the average lifespan of this mutant cat obviously increased and even went
                            above and beyond. The ring of light under their feet also enabled them to send signals to
                            one another from far away and supply the major energy so that they could become physically
                            fast-moving and agile.
                        </p>
                    </div>
                    <div className="box lg:flex items-center justify-between mt-20 lg:mt-[170px]">
                        <div className="box-img mb-10">
                            <img src={images.meoback} alt="meoback" className="mx-auto max-w-[236px] md:max-w-[none]" />
                        </div>
                        <div className="lg:max-w-[676px]">
                            <p className="note">
                                With these factors in mind, fresh hope was raised for human beings on the possibility of
                                changing the genetic structure to adapt to the harsh conditions of space as well as
                                possessing those extraordinary abilities based on the in-depth study of the Mew-O
                                species.
                            </p>
                            <p className="note mt-5">
                                Therefore, hundreds of shuttles were dispatched on a space mission to gather these 5000
                                cosmic cats with the goal of ushering in a new era of space conquest.
                            </p>
                        </div>
                    </div>
                    <div className="box mt-4">
                        <div className="box-img lg:hidden">
                            <img
                                src={images.mewbot}
                                alt="meobot"
                                className="mx-auto max-w-[235px] mt-[30px] mb-[30px]"
                            />
                        </div>
                        <p className="note text-center mt-6 lg:mt-[160px]">
                            So what really happened during that mysterious time and will the experiment to transplant
                            <br />
                            Mew-O's genes into humans succeed?
                        </p>
                    </div>
                    <div className="box text-center lg:mt-[24px]">
                        <h4 className="title text-center lg:text-[40px]">
                            It’s time to embark on a journey of discovery!
                        </h4>
                        <div className="relative group lg:mt-[63px] mb-28">
                            <div className="ov"></div>
                            <Button
                                href="https://www.app.ozozoz.io/launchpad/Mew-O"
                                target="_blank"
                                primary
                                hover
                                className="w-[120px] h-[38px] mx-auto mt-7 lg:w-[133[px] lg:h-[46px] lg:text-xl relative z-10"
                            >
                                Mint Now
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </StyledSection>
    );
};

const StyledSection = styled.section`
    overflow: hidden;
    .box-img {
        max-width: 800px;
        img {
            width: 100%;
        }
    }
    .box {
        .title {
            text-shadow: 1.5px 0px 0px #c4a946;
            @media screen and (max-width: 48em) {
                text-shadow: 1px 0px 0px #c4a946;
            }
        }
        .ov {
            background: #ffe174;
            filter: blur(34px);
            width: 119.8px;
            height: 45.14px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 10;
        }
    }
`;

export default BackStoryContent;
