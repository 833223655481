import { Icon } from '@iconify/react';
import styled, { css } from 'styled-components';
import NavHeader from '../header/NavHeader';
import Portal from '../portal/Portal';
import { useState } from 'react';
import Button from '../button/Button';
const Menu = () => {
    const [isShow, setIsShow] = useState<boolean>(false);
    return (
        <>
            <div className="icon" onClick={() => setIsShow(!isShow)}>
                <Icon icon="charm:menu-hamburger" className="text-32" />
            </div>
            <Portal>
                <>
                    {isShow && <div className="overlay" onClick={() => setIsShow(!isShow)}></div>}
                    <StyledMenu isShow={isShow} className="fixed top-0 left-0 bottom-0 flex flex-col">
                        <NavHeader />
                        <div className="list-btn flex flex-col mt-auto mb-32">
                            <Button second hover className="btn" to="/backstory" onClick={() => setIsShow((s) => !s)}>
                                Back Story
                            </Button>
                            <Button primary hover className="btn">
                                Mint Now
                            </Button>
                        </div>
                    </StyledMenu>
                </>
            </Portal>
        </>
    );
};

const StyledMenu = styled.menu<any>`
    background-color: #233030;
    transform: translateX(-100%);
    height: 100vh;
    width: 60%;
    z-index: 100;
    transition: 0.2s linear;
    ${(props) =>
        props.isShow &&
        css`
            transform: translateX(0%);
        `};
    .btn {
        width: 80%;
        height: 39px;
        margin: 0 auto;
        :first-child {
            margin-bottom: 24px;
        }
    }
`;

export default Menu;
