import { useMediaPredicate } from 'react-media-hook';

const sc = {
    Sm: (): boolean => {
        const mobile = useMediaPredicate('(min-width: 576px)');
        return mobile;
    },
    Md: (): boolean => {
        const tablet = useMediaPredicate('(min-width: 768px)');
        return tablet;
    },
    Lg: (): boolean => {
        const laptop = useMediaPredicate('(min-width: 1025px)');
        return laptop;
    },
    Xl: (): boolean => {
        const desktop = useMediaPredicate('(min-width: 1440px)');
        return desktop;
    },
};
export default sc;
