import { Icon } from '@iconify/react';
import styled from 'styled-components';
import images from '../../assets/images';
import Button from '../../components/button/Button';
import HomeBackstory from './HomeBackstory';

const HomeBanner = () => {
    return (
        <StyledBanner className="relative">
            <div className="container-video w-full h-full relative  ">
                <video
                    autoPlay
                    muted
                    playsInline
                    loop
                    className="intro top-0  left-0 h-full 2xl:h-[120%] 2xl:-top-[10%] object-cover xl:object-none 2xl:object-fill"
                >
                    <source src="/videos/banner.mp4" />
                </video>
                <div className="noise absolute inset-0 ">
                    <img src={images.noise} alt="noise" />
                </div>
                <HomeBackstory className="hidden lg:flex" />
            </div>
            <div className="container flex justify-end">
                <div className="box-content  lg:absolute  z-20 max-w-[514px]">
                    <h4 className="title">MEW-O</h4>
                    <p className="text-lg lg:text-xl font-medium leading-[27px]">
                        The 1st NFT Collection launched on the OzOzOz marketplace with 2 unique features: Frozen NFT and
                        AMM NFT, aiming to facilitate smoother automated trades.
                    </p>
                    <p className="text-pri underline  font-semibold mt-12 text-20 lg:text-28">Discover on:</p>
                    <div className="list-btn flex gap-6 mt-4 lg:gap-10">
                        <Button className="text-24" href="https://discord.com/invite/CYuavf9unU" target="_blank">
                            <Icon icon="akar-icons:discord-fill" className="text-24 lg:text-36 hover:text-pri" />
                        </Button>
                        <Button className="text-24" href="https://twitter.com/MewO_NFT" target="_blank">
                            <Icon icon="akar-icons:twitter-fill" className="text-24 lg:text-36 hover:text-pri" />
                        </Button>
                    </div>
                </div>
            </div>
            <HomeBackstory className="flex lg:hidden" />
        </StyledBanner>
    );
};

const StyledBanner = styled.section`
    position: relative;
    top: -2%;
    width: 100%;
    transform: translateY(-90px);

    @media screen and (min-width: 1024px) {
        /* height: 100vh; */
    }

    .container-video {
        height: 100%;
        /* position: absolute; */
        height: fit-content;
        top: -12%;
        @media screen and (max-width: 1600px) {
            top: -5%;
        }
        @media screen and (max-width: 48em) {
            top: 0;
        }
        ::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100px;
            bottom: -4%;
            left: 0;
            background: #233030;
            filter: blur(25px);
            z-index: 20;
            @media screen and (max-width: 1280px) {
                bottom: -9%;
            }
            @media screen and (max-width: 48em) {
                filter: blur(13px);
                height: 95px;
                bottom: -20%;
            }
        }
        @media screen and (min-width: 1536px) {
            /* height: 118vh; */
        }
        @media screen and (max-width: 1536px) {
            height: fit-content;
        }
    }
    video {
        /* position: absolute;
        width: 100%;
        z-index: 10; */
        width: 100%;
        max-height: 100%;
        height: 100%;
        object-fit: cover;
    }
    .box-content {
        @media screen and (min-width: 1025px) {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
        .title {
            text-shadow: 1.62222px 1.62222px 3.24444px #c4a946;
            font-family: 'Rajdhani';
            font-weight: 700;
            font-size: 146px;
            line-height: 102px;
            margin-bottom: 11px;
            position: relative;
            z-index: 100;
            @media screen and (max-width: 48em) {
                position: static;
                line-height: 102px;
                font-size: 101px;
            }
        }
    }
    @media screen and (max-width: 48em) {
        .container-video {
            height: 336px;
            width: 147%;
        }
        video {
            position: absolute;
            height: 100%;
            width: 115%;
        }
    }
    @media screen and (min-width: 1600px) {
        top: -10%;
    }
    @media screen and (max-width: 48em) {
        transform: translateY(0);
    }
`;

export default HomeBanner;
