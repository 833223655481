import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styled, { css, StyledComponent } from 'styled-components';

interface PropsButton {
    to?: string;
    href?: string;
    children?: ReactNode;
    leftIcon?: ReactNode;
    rightIcon?: string;
    primary?: boolean;
    second?: boolean;
    linkIcon?: boolean;
    disabled?: boolean;
    target?: string;
    onClick?: (e: any) => void;
    className?: string;
    hover?: boolean;
}
const Button = React.forwardRef<HTMLDivElement, PropsButton>(
    (
        {
            to,
            href,
            children,
            leftIcon,
            rightIcon,
            primary = false,
            second = false,
            linkIcon = false,
            disabled = false,
            hover = false,

            onClick = () => {},
            className,
            ...passProps
        },
        ref,
    ) => {
        let Comp: any = 'button';
        const props = {
            onClick,
            ...passProps,
        };
        if (to) {
            Comp = Link;
        } else if (href) {
            Comp = 'a';
        }
        return (
            <StyledButton
                to={to}
                className={`wrapper ${className ? className : ''}`}
                primary={primary}
                second={second}
                disabled={disabled}
                linkIcon={linkIcon}
                leftIcon={leftIcon}
                rightIcon={rightIcon}
                hover={hover}
                ref={ref}
            >
                <Comp className="comp-btn" href={href} {...props} to={to}>
                    <>
                        {leftIcon && <span className="icon">{leftIcon}</span>}
                        <span className="title">{children}</span>
                        {rightIcon && <span className="icon">{rightIcon}</span>}
                    </>
                </Comp>
            </StyledButton>
        );
    },
);
const StyledButton = styled.div<PropsButton>`
    display: inline-flex;
    cursor: pointer;

    + .wrapper {
        margin-left: 10px;
        display: inline-flex;
        align-items: center;
    }

    .title + .icon,
    .icon + .title {
        margin-left: 8px;
    }
    a {
        &.comp-btn {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .comp-btn {
        font-weight: 600;
        cursor: pointer;
        text-align: center;
        user-select: none;
        width: 100%;
        height: 100%;
        color: inherit;

        ${(props) =>
            props.primary &&
            css`
                background: #c4a946;
                box-shadow: 0px 2px 3px rgba(196, 169, 70, 0.5);
                color: #28261d;
                border-radius: 15px;
            `};
        ${(props) =>
            props.second &&
            css`
                background-color: transparent;
                border: 1px solid #c4a946;
                color: #e934d7;
                border-radius: 15px;
                color: #c4a946;
                box-shadow: 0px 2px 3px rgba(196, 169, 70, 0.5);
            `};

        ${(props) =>
            props.linkIcon &&
            css`
                background-color: #343646;
                border: 1px solid #e934d7;
                color: #e934d7;
                font-size: 1.2rem;
                border-radius: inherit;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: inherit;
                .title {
                    display: flex;
                    justify-content: center;
                    font-size: inherit;
                }

                @media screen and (max-width: 64em) {
                    /* color: var(--pri); */
                }
            `};
        ${(props) =>
            props.hover &&
            css`
                :hover {
                    background: #ffe174;
                }
            `};
    }
    @media screen and (max-width: 48em) {
    }
`;
export default Button;
