import styled from 'styled-components';
import images from '../../assets/images';
import IconMew from '../../components/icons/IconMew';
import StyledTitle from '../../styles/StyledTitle';

const HomeFreeze = () => {
    return (
        <StyledFreeze className="mt-[150px]">
            <div className="container">
                <div className="inner">
                    <div className="box-title relative mb-[32px] lg:mb-[100px]">
                        <div className="icon">
                            <IconMew />
                        </div>
                        <StyledTitle className="text-center ">Freeze Mew-O</StyledTitle>
                    </div>
                    <div className="box-img relative">
                        <div className="over"></div>
                        <img src={images.freeze} alt="Freeze Roadmap" className="mx-auto" />
                    </div>
                </div>
            </div>
        </StyledFreeze>
    );
};

const StyledFreeze = styled.div`
    .box-img {
        .over {
            background: #2d564b;
            filter: blur(225px);
            width: 726px;
            height: 726px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: -1;
            @media screen and (max-width: 48em) {
                top: 20%;
                width: 240px;
                height: 240px;
                filter: blur(50px);
            }
        }
    }
    .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        svg {
            @media screen and (max-width: 48em) {
                width: 44px;
            }
        }
    }
`;

export default HomeFreeze;
