const images = {
    logo: require('./logo.svg').default,
    freeze: require('./freeze.png'),
    amm: require('./amm.png'),
    footer: require('./footer.svg').default,
    main: require('./mainbackstory.svg').default,
    spaceship: require('./spaceship.png'),
    hanhtinh: require('./hanhtinh.svg').default,
    meoback: require('./meo-back.svg').default,
    mewbot: require('./mewbot.svg').default,
    footermobile: require('./footermobile.svg').default,
    footerback: require('./footerback.svg').default,
    noise: require('./Noise.png'),
};
export default images;
