import styled from 'styled-components';
import main from '../../assets/images/mainbackstory.svg';
import mainmobile from '../../assets/images/backstorymobile.svg';
import BackStoryTitle from './BackStoryTitle';
import BackStoryContent from './BackStoryContent';
import tw from 'twin.macro';
import images from '../../assets/images';

const BackStory = () => {
    return (
        <StyledBackStory className="backstory ">
            <BackStoryTitle />
            <BackStoryContent />
            <div className="img lg:hidden relative z-[2]">
                <img src={images.footermobile} alt="footer" className="w-full" />
            </div>
            <div className="img hidden lg:block relative z-[2]">
                <img src={images.footerback} alt="footer" className="w-full" />
            </div>
        </StyledBackStory>
    );
};

const StyledBackStory = styled.div`
    background-image: url(${mainmobile});
    background-repeat: no-repeat;

    @media screen and (min-width: 430px) {
        background-size: cover;
    }
    @media screen and (min-width: 1025px) {
        background-image: url(${main});
    }
    .note {
        ${tw`
            text-13 leading-8 md:text-base xl:text-24  lg:leading-[36px] font-medium lg:font-normal
        `}
        @media screen and (min-width: 1025px) {
            line-height: 36px;
        }
    }
`;
export default BackStory;
