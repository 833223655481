import { Link, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import images from '../../assets/images';
import sc from '../../views';
import Button from '../button/Button';
import Menu from '../menu/Menu';
import NavHeader from './NavHeader';

const Header = () => {
    const location = useLocation();
    const home = location.pathname === '/';

    return (
        <StyledHeader className="py-7 fixed top-0 left-0 right-0 w-full z-40" home={home}>
            <div className="container">
                <div className="inner flex items-center justify-between">
                    {!sc.Lg() && <Menu />}
                    <div className="logo">
                        <Link to="/" className="flex items-center gap-4 lg:gap-5">
                            <img src={images.logo} alt="logo" className="w-[28px] lg:w-[36px]" />
                            <p className="text-26 text-pri lg:text-32">MEW-O</p>
                        </Link>
                    </div>
                    {sc.Lg() && <NavHeader />}
                    <div className="relative group">
                        <Button
                            href="https://www.app.ozozoz.io/launchpad/Mew-O"
                            target="_blank"
                            primary
                            hover
                            className="text-black font-semibold w-[89px] h-[32px] text-md lg:w-[133px] lg:h-[46px] lg:text-xl relative"
                        >
                            Mint Now
                        </Button>
                    </div>
                </div>
            </div>
        </StyledHeader>
    );
};

const StyledHeader = styled.header<any>`
    background: rgba(35, 48, 48, 0.7);
    ${(props) => props.home && css``};
    .logo {
        p {
            font-family: 'Onramp';
        }
    }
    /* .container {
        @media screen and (min-width: 1536px) {
            padding: 0 165px;
            max-width: 100%;
        }
    } */
`;

export default Header;
