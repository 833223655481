import styled from 'styled-components';

const BackStoryTitle = () => {
    return (
        <StyledSection>
            <div className="container">
                <div className="inner">
                    <h2 className="title text-center text-24 font-bold pt-[20px] lg:pt-0 lg:text-[70px] lg:leading-[105px] translate-y-28">
                        Mystery of the First and Only Cat to Travel to Space
                    </h2>
                    <p className="note  mt-[100px] lg:max-w-[875px]  lg:mx-auto lg:mt-28">
                        In 1963, a cat named Mew-O became the first and only feline to travel to space in a French
                        rocket program with the aim of understanding the ability of living things to survive the effects
                        of space travel.
                    </p>
                    <div className="max-w-[260px] md:max-w-[800px]  justify-center mx-auto mt-[120px]  xl:pl-[180px] xl:mt-[160px] 2xl:mt-[300px] lg:pl-20">
                        <p className="note mb-5">
                            After enduring months of grueling training, she had permanent electrodes and a unique DNA
                            surgically implanted into her brain to assess neurological activity.
                        </p>
                        <p className="note block">
                            Eventually, Mew-O defeated the finalist candidates to be chosen on launch day to board the
                            AG1 rocket.
                        </p>
                    </div>
                    <p className="note max-w-[260px] ml-auto mt-[42px] md:max-w-[557px] lg:mt-[120px]">
                        However, the daring cat was reported missing when she fell off the spacecraft in an asteroid
                        collision and got pulled into the black hole.
                    </p>
                    <p className="note mt-[120px] lg:max-w-[800px] mx-auto lg:ml-[355px]  xl:pl-[160px]">
                        In 2022, the astronauts from a US spaceship, as part of NASA's program, accidentally discovered
                        a glittering cat floating in space. To their surprise, this cat was identical to the first
                        spacefaring feline that was supposed to be dead 59 years ago.
                    </p>
                    <p className="note mt-5 lg:max-w-[800px] mx-auto lg:ml-[355px]  xl:pl-[160px]">
                        However, this cosmic cat appeared to have a supernatural ability as it could move as fast as the
                        speed of light.
                    </p>
                </div>
            </div>
        </StyledSection>
    );
};

const StyledSection = styled.section`
    .title {
        text-shadow: 0.550353px 0.550353px 1.10071px #c4a946;
        line-height: 36px;
        text-transform: uppercase;
        font-family: 'Rajdhani';
        font-weight: 700;
        max-width: 1193px;
        margin-left: auto;
        margin-right: auto;
        @media screen and (min-width: 1025px) {
            line-height: 95px;
            padding-top: 40px;
        }
        @media screen and (min-width: 1546px) {
            padding-top: 70px;
        }
    }
`;

export default BackStoryTitle;
