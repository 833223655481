import { Icon } from '@iconify/react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const NavHeader = () => {
    return (
        <StyledNav>
            <ul className="menu mt-32 flex-col pl-12 lg:pl-0 flex lg:items-center gap-12 lg:gap-24 lg:flex-row lg:mt-0">
                {DataMenu.map((item, index) => (
                    <li
                        key={index}
                        className={`menu-item group`}
                        // onClick={() => }
                    >
                        {index === 0 ? (
                            <NavLink
                                to={item.path}
                                // className={({ isActive }: any) => {
                                //     return isActive ? 'text-pri' : '';
                                // }}
                                className={`font-medium hover:text-pri hover:font-semibold flex items-center gap-6 `}
                            >
                                <div className={`icon lg:hidden text-28 w-[32px] ${item.active && 'text-pri '}`}>
                                    <Icon icon={item.icon} className="" />
                                </div>
                                <p className={` text-20 ${item.active ? 'text-pri font-semibold' : ''}`}>{item.text}</p>
                                {item.coming && (
                                    <span className="absolute text-[10px] bottom-4  hidden group-hover:block">
                                        Coming Soon
                                    </span>
                                )}
                            </NavLink>
                        ) : (
                            <a
                                href={item.path}
                                target="_blank"
                                className={`font-medium hover:text-pri hover:font-semibold flex items-center gap-6 `}
                                rel="noreferrer"
                            >
                                <div className={`icon lg:hidden text-28 w-[32px] ${item.active && 'text-pri '}`}>
                                    <Icon icon={item.icon} className="" />
                                </div>
                                <p className={` text-20 ${item.active ? 'text-pri font-semibold' : ''}`}>{item.text}</p>
                                {item.coming && (
                                    <span className="absolute text-[10px] bottom-4  hidden group-hover:block">
                                        Coming Soon
                                    </span>
                                )}
                            </a>
                        )}
                    </li>
                ))}
            </ul>
        </StyledNav>
    );
};

const StyledNav = styled.nav`
    li {
        :nth-child(1) {
            .icon {
                font-size: 32px;
            }
        }
    }
`;

const DataMenu: {
    path: string;
    text: string;
    active: boolean;
    menu?: any;
    coming: boolean;
    icon: string;
}[] = [
    { path: '/', text: 'Home', active: true, coming: false, icon: 'ic:round-home' },
    {
        path: 'https://www.app.ozozoz.io/frozen',
        text: 'Freeze',
        active: false,
        coming: false,
        icon: 'game-icons:ice-cube',
    },
    {
        path: 'https://docs.mew-o.com/introduction/mew-o-backstory',
        text: 'Docs',
        active: false,
        coming: false,
        icon: 'jam:paper-plane-f',
    },
];

export default NavHeader;
