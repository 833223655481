import styled from 'styled-components';
import { Child } from '../../types/global';
import Header from '../header/Header';

const DefaultLayout = ({ children }: Child) => {
    return (
        <div className="inner">
            <Header />
            <main className="main-root">{children}</main>
        </div>
    );
};

export default DefaultLayout;
